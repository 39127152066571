import { UseCalendarParams } from './../@types/calendar.d';
import { useState, useMemo } from 'react'

import {
  getMonthesNames,
  createMonth,
  getWeekDaysNames,
  getMonthNumberOfDays,
  createDate
} from '../functions/date'


const DAYS_IN_WEEK = 7

const getYearsInterval = (year: number) => {
  const startYear = Math.floor(year / 10) * 10
  return [...Array(10)].map((_, index) => startYear + index)
}

export const useCalendar = ({
  locale = 'default',
  selectedDate: date,
  firstWeekDayNumber = 2
}: UseCalendarParams) => {
  const [mode, setMode] = useState<'days' | 'monthes' | 'years'>('days')
  const [selectedMonth, setSelectedMonth] = useState(
    createMonth({ date: new Date(date.year, date.monthIndex), locale })
  )
  const [selectedYear, setSelectedYear] = useState(date.year)
  const [selectedYearsInterval, setSelectedYearsInterval] = useState(
    getYearsInterval(date.year)
  )

  const monthesNames = useMemo(() => getMonthesNames(locale), [])
  const weekDaysNames = useMemo(() => getWeekDaysNames(firstWeekDayNumber, locale), [])

  const days = useMemo(() => selectedMonth.createMonthDays(), [selectedMonth, selectedYear])

  const calendarDays = useMemo(() => {
    const monthNumberOfDays = getMonthNumberOfDays(selectedMonth.monthIndex, selectedYear);

    const prevMonthDays = createMonth({
      date: new Date(selectedYear, selectedMonth.monthIndex - 1),
      locale
    }).createMonthDays()

    const nextMonthDays = createMonth({
      date: new Date(selectedYear, selectedMonth.monthIndex + 1),
      locale
    }).createMonthDays()
  
    const firstDay = days[0]
    const lastDay = days[monthNumberOfDays - 1]
  
    const shiftIndex = firstWeekDayNumber - 1
    const numberOfPrevDays =
      firstDay.dayNumberInWeek - 1 - shiftIndex < 0
        ? DAYS_IN_WEEK - (firstWeekDayNumber - firstDay.dayNumberInWeek)
        : firstDay.dayNumberInWeek - 1 - shiftIndex
  
    const numberOfNextDays =
      DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex > 6
        ? DAYS_IN_WEEK - lastDay.dayNumberInWeek - (DAYS_IN_WEEK - shiftIndex)
        : DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex

    const totalNumberOfNextDays = numberOfNextDays + 7
  
    const totalCalendarDays = days.length + numberOfPrevDays + totalNumberOfNextDays
  
    const result: ReturnType<typeof createDate>[] = []
  
    for (let i = 0; i < numberOfPrevDays; i += 1) {
      const inverted = numberOfPrevDays - i
      result[i] = prevMonthDays[prevMonthDays.length - inverted]
    }
  
    for (let i = numberOfPrevDays; i < totalCalendarDays - totalNumberOfNextDays; i += 1) {
      result[i] = days[i - numberOfPrevDays]
    }
  
    for (let i = totalCalendarDays - totalNumberOfNextDays; i < totalCalendarDays; i += 1) {
      result[i] = nextMonthDays[i - totalCalendarDays + totalNumberOfNextDays]
    }
  
    return result
  }, [selectedMonth.year, selectedMonth.monthIndex, selectedYear, days, firstWeekDayNumber, locale])
  

  const onClickArrow = (direction: 'right' | 'left') => {
    if (mode === 'years' && direction === 'left') {
      return setSelectedYearsInterval(getYearsInterval(selectedYearsInterval[0] - 10))
    }

    if (mode === 'years' && direction === 'right') {
      return setSelectedYearsInterval(getYearsInterval(selectedYearsInterval[0] + 10))
    }

    if (mode === 'monthes' && direction === 'left') {
      const year = selectedYear - 1
      if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year))
      return setSelectedYear(selectedYear - 1)
    }

    if (mode === 'monthes' && direction === 'right') {
      const year = selectedYear + 1
      if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year))
      return setSelectedYear(selectedYear + 1)
    }

    if (mode === 'days') {
      const monthIndex =
        direction === 'left' ? selectedMonth.monthIndex - 1 : selectedMonth.monthIndex + 1
      if (monthIndex === -1) {
        const year = selectedYear - 1
        setSelectedYear(year)
        if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year))
        return setSelectedMonth(createMonth({ date: new Date(selectedYear - 1, 11), locale }))
      }

      if (monthIndex === 12) {
        const year = selectedYear + 1
        setSelectedYear(year)
        if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year))
        return setSelectedMonth(createMonth({ date: new Date(year, 0), locale }))
      }

      setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex), locale }))
    }
  }

  const setSelectedMonthByIndex = (monthIndex: number) => {
    setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex), locale }))
  }

  return {
    state: {
      mode,
      calendarDays,
      weekDaysNames,
      monthesNames,
      // selectedDay,
      selectedMonth,
      selectedYear,
      selectedYearsInterval
    },
    functions: {
      onClickArrow,
      setMode,
      // setSelectedDay,
      setSelectedMonthByIndex,
      setSelectedYear,
      setSelectedYearsInterval
    }
  }
}
