export const languageTranslate: { [p: string]: string } = {
  en: 'English',
  ru: 'Русский',
  kk: 'Қазақша',
  uz: 'Узбекский',
  ar: 'Арабский',
  az: 'Azərbaycan',
  be: 'Белорусский',
  bn: 'Бенгальский',
  zh: 'Китайский',
  fi: 'Финский',
  fr: 'Французский',
  de: 'Немецкий',
  hi: 'Хинди',
  id: 'Индонезийский',
  it: 'Итальянский',
  ja: 'Японский',
  ky: 'Киргизский',
  pl: 'Польский',
  pt: 'Португальский (Португалия)',
  es: 'Испанский',
  sv: 'Шведский',
  tr: 'Турецкий',
  uk: 'Украинский',
  kz: 'Шала-Казахский',
  'pt-BR': 'Португальский (Бразилия)',
}

export const mainSettingsTranslate: { [p: string]: string } = {
  language: 'Язык',
  use_stream: 'Распознавание',
  decoder_name: 'Словарь',
  sample_rate: 'кГц',
}

export const additionalAsrConfig = [
  {
    name: 'convert_digits',
    title: 'Перевод чисел',
  },
  {
    name: 'use_punctuation',
    title: 'Пунктуация',
  },
  {
    name: 'translate',
    title: 'Перевод англоязычных названий',
  },
  {
    name: 'restore_case',
    title: 'Соблюдение верхнего регистра',
  },
  {
    name: 'profanity_filter',
    title: 'Мат',
  },
  {
    name: 'classify',
    title: 'Определить автоответчик',
  },
  {
    name: 'include_breaks',
    title: 'Интервалы распознаваний',
  },
  {
    name: 'split_channels',
    title: 'Разбивка аудио на каналы',
  },
]
