import { memo } from "react"
import { ISessionPagination } from "../@types/console"
import classes from '../styles/pagination.module.scss'
import cn from 'classnames'
import { CustomIcon } from "./customIcon/customIcon"


export const Pagination = memo(({ currentPage, totalPages, onPageChange }: ISessionPagination) => {
    const renderPageButton = (pageNumber: number) => {
        const isActive = pageNumber === currentPage
        return (
            <button
                key={pageNumber}
                onClick={() => onPageChange(pageNumber)}
                className={cn(classes.paginationButton, isActive && classes.active)}
            >
                {pageNumber}
            </button>
        )
    }

    const renderPaginationButtons = () => {
        const buttons = []

        let start
        let end

        if (totalPages <= 5) {
            start = 2
            end = 4
        }
        else if (currentPage < 3) {
            start = 2
            end = 3
        } else if (currentPage === totalPages) {
            start = currentPage - 2
            end = totalPages - 1
        } else if (currentPage >= totalPages - 2) {
            start = currentPage - 1
            end = totalPages - 1
        } else {
            start = currentPage - 1
            end = currentPage + 1
        }

        buttons.push(renderPageButton(1))

        if (currentPage > 3 && totalPages > 5) {
            buttons.push(<span className={classes.paginationElipsis} key="leftEllipsis">...</span>)
        }

        for (let i = start; i <= end; i++) {
            buttons.push(renderPageButton(i))
        }

        if (currentPage < totalPages - 2 && totalPages > 5) {
            buttons.push(<span className={classes.paginationElipsis} key="rightEllipsis">...</span>)
        }

        buttons.push(renderPageButton(totalPages))

        return buttons
    }

    return (
        <div className={classes.pagination}>
            <button
                disabled={currentPage === 1}
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage - 1)}
            >
                <CustomIcon icon="arrowRight" />
            </button>
            {renderPaginationButtons()}
            <button
                disabled={currentPage === totalPages}
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage + 1)}
            >
                <CustomIcon icon="arrowRight" />
            </button>
        </div>
    )
})