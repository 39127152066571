import { FC, useState } from 'react'
import { IMessage } from '../../@types/asr'
import classes from '../../styles/asr/asrMessage.module.scss'

import ToggleText from './ToggleText'
import { MessageHeader } from './messageHeader'
import WaveSurfer from 'wavesurfer.js'

export const Message: FC<{ item: IMessage; searchString: string }> = ({ item, searchString }) => {
  const [player, setPlayer] = useState<null | WaveSurfer>(null)
  const { id } = item
  
  return (
    <>
      <div className={classes.asrResult}>
        <MessageHeader setPlayer={setPlayer} item={item} />
        {item.isMessageOpen && (
          <>
            <div className={classes.asrToggleTextBody}>
              {item.textNotFiltredShow && (
                <ToggleText
                  title="Распознанный текст"
                  textArray={item.text}
                  id={id}
                  searchString={searchString}
                  player={player}
                />
              )}
              {item.textFiltredShow && (
                <ToggleText
                  id={id}
                  title="Фильтрованный текст"
                  text={item.textFilterd}
                  searchString={searchString}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
