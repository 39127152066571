import { createDate } from './createDate'

export const formatDate = (date: Date | null, format: string) => {
  const d = createDate({ date })

  return format
    .replace(/\bYYYY\b/, d.year.toString())
    .replace(/\bYYY\b/, d.yearShort)
    .replace(/\bWW\b/, d.week.toString().padStart(2, '0'))
    .replace(/\bW\b/, d.week.toString())
    .replace(/\bDDDD\b/, d.day)
    .replace(/\bDDD\b/, d.dayShort)
    .replace(/\bDD\b/, d.dayNumber.toString().padStart(2, '0'))
    .replace(/\bD\b/, d.dayNumber.toString())
    .replace(/\bMMMM\b/, d.month)
    .replace(/\bMMM\b/, d.monthShort)
    .replace(/\bMM\b/, d.monthNumber.toString().padStart(2, '0'))
    .replace(/\bM\b/, d.monthNumber.toString())
}

export const formatDateForRequest = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') 
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}.${month}.${year}, ${hours}:${minutes}`
}

const declension = (num: number, singular: string, few: string, many: string): string => {
  const lastDigit = num % 10
  const lastTwoDigits = num % 100
  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return many
  } else if (lastDigit === 1) {
    return singular
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return few
  } else {
    return many
  }
}

export const formatTime = (seconds: number): string  => {
  const totalSeconds = Math.floor(seconds)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const remainingSeconds = totalSeconds % 60

  const hoursString = hours ? `${hours} ${declension(hours, 'час', 'часа', 'часов')} `: ''
  const minutesString = minutes ? `${minutes} ${declension(minutes, 'минута', 'минуты', 'минут')} ` : ''
  const secondsString = remainingSeconds ? `${remainingSeconds} ${declension(remainingSeconds, 'секунда', 'секунды', 'секунд')}` : ''

  return hoursString + minutesString + secondsString
}
