import { Container } from "../../components/container"
import { AdminHeader } from "../../layouts/admin/adminHeader"
import { UsersList } from "../../layouts/admin/usersList"
import { AdminModal } from "../../layouts/admin/adminModal"


export const AdminPage = () => {
    return (
        <>
          <Container>
            <section>
              <AdminHeader />
              <UsersList />
              <AdminModal />
            </section>
          </Container>
        </>
      )
}
