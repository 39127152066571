import { ButtonHTMLAttributes, cloneElement, FC, memo, ReactElement, ReactNode, useState } from 'react'
import classes from '../styles/customButton.module.scss'
import { OutClick } from './outClick'
import cn from 'classnames'

interface ICustomButtonSelect extends ButtonHTMLAttributes<HTMLButtonElement> {
  contentComponent: ReactNode
  active?: boolean
  classes?: { content?: string; button?: string; outClick?: string }
  triangle?: boolean
  placement?: string
  cloneChild?: boolean
  hideSelect?: boolean
}

export const CustomButtonSelect: FC<ICustomButtonSelect> = memo(
  ({
    children,
    contentComponent,
    classes: customClasses,
    triangle,
    placement,
    cloneChild,
    hideSelect,
    active,
    ...buttonProps
  }) => {
    const [open, setOpen] = useState(false)

    const { onClick, ...rest } = buttonProps

    return (
      <OutClick
        className={cn(
          classes.outClickCustomSelect,
          'outClickSelectBtn',
          customClasses?.outClick && customClasses.outClick
        )}
        removeListener={!open || hideSelect}
        onClick={() => setOpen(false)}
      >
        <div className={cn(classes.customButtonWrapper)}>
          <button
            onClick={(e) => {
              !hideSelect && setOpen((p) => !p)
              onClick && onClick(e)
            }}
            className={cn(
              classes.customButton,
              'selectCustomBtn',
              customClasses?.button && customClasses.button,
              (open || active) && classes?.activeButton
            )}
            {...rest}
          >
            {children}
          </button>
          {!hideSelect && (
            <div
              className={cn(
                classes.selectButtonContentWrapper,
                'selectButtonContentWrapper',
                triangle && classes.triangledContent,
                placement,
                customClasses?.content && customClasses.content,
                open && classes.active,
                open && 'active'
              )}
            >
              {cloneChild ? cloneElement(contentComponent as ReactElement, { setOpen }, null) : contentComponent}
            </div>
          )}
        </div>
      </OutClick>
    )
  }
)

CustomButtonSelect.displayName = 'CustomButtonSelect'
