import { FC, ChangeEvent } from "react"
import { CustomIcon } from "../customIcon/customIcon"
import classes from '../../styles/textField.module.scss'
import cn from 'classnames'


export const SearchField: FC<{
    localeText?: string
    searchString: string
    handleClearInput: () => void
    handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({
    localeText,
    searchString,
    handleClearInput,
    handleChangeInput
}) => {
    return (
        <div className={classes.inputBox}>
            <div className={cn(classes.inputIcon, !searchString && classes.empty)}><CustomIcon icon="magnifier" /></div>
            <input
                type="text"
                style={searchString ? {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                }: {}}
                className={classes.filterInput}
                placeholder={localeText || "Поиск"}
                value={searchString || ''}
                onChange={handleChangeInput}
            />
            {searchString && (
                <button onClick={handleClearInput}>
                    <CustomIcon icon="cross" />
                </button>
            )}
        </div>
    )
}
