import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from '../styles/errorPages.module.scss'
import { formatSeconds } from '../functions/common'

const NoMatch = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(5)

  const redirectToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCountdown((prevCount) => prevCount - 1)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [countdown])

  useEffect(() => {
    if (countdown === 0) {
      redirectToHome()
    }
  }, [countdown])

  return (
    <div className={classes.errorPageWrapper}>
      <p>404 - Страница не найдена</p>
      <p>
        Вы будете перенаправлены на{' '}
        <span className={classes.errorPageLink} onClick={redirectToHome}>
          главную страницу
        </span>{' '}
        через {countdown} {formatSeconds(countdown)}
      </p>
    </div>
  )
}

export default NoMatch
