import { FC, ReactNode, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { asrUpload } from '../store/slices/asr/async'
import { useDispatch } from 'react-redux'

import classes from '../styles/asr/asrDropzone.module.scss'
import { useSelector } from '../hooks/redux'

export const AsrDropzone: FC<{ children: ReactNode; disabled?: boolean }> = ({ children, disabled }) => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.asr.selectedAsrSettings)


  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      dispatch(asrUpload({
        convert_digits: formData.convert_digits,
        use_punctuation: 0,
        include_breaks: formData.include_breaks,
        timestamps: 0,
        translate: formData.translate,
        restore_case: 0,
        profanity_filter: formData.profanity_filter,
        classify: formData.classify,
        decoder_name: formData.decoder_name,
        use_stream: formData.use_stream,
        split_channels: formData.split_channels,
        audio_blob: acceptedFiles
      }))
    },
    [dispatch, formData]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && <div className={classes.dropzoneActiveWrapper} />}
      {children}
      {isDragActive && <span className={classes.dropzoneTitle}>Перетащите сюда файл</span>}
    </div>
  )
}
