import { createAsyncThunk } from '@reduxjs/toolkit'
import { urlConsole, urlAsr, urlSynthesis } from '../../../config'
import axios from 'axios'
import { TConsoleSend, TConsoleMode } from '../../../@types/console'
import { setConsoleError, setErrorText } from './consoleSlice'
import JSZip from 'jszip'

export const consoleSend = createAsyncThunk('console/consoleSend', async (data: TConsoleSend, { dispatch }) => {
  try {
    const stats = await axios.post(urlConsole + '/get_stats', { ...data })
    const sessions = await axios.post(urlConsole + '/get_sessions', { ...data })
    return {
      stats: {
        ...stats?.data,
        limit: data.limit,
        service_type: data.service_type
      },
      sessions: sessions?.data?.sessions
    }
  } catch (e) {
    dispatch(setConsoleError(true))
    if (e instanceof Error) {
      dispatch(setErrorText(e.message))
    } else {
      dispatch(setErrorText('Error'))
    }
  }
})

export const fetchAudio = async ({token, audioLink}: {
  token?: string
  audioLink: string
}) => {
  try {
    const authResponse = await axios.post(urlConsole + '/auth', { auth_token: token })

    if (authResponse.status === 200) {
      const authToken = authResponse.data.authToken

      const audioResponse = await axios.get(audioLink, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (audioResponse.status === 200) {
        const audioData = audioResponse.data
        console.log(audioData)
      } else {
        console.error('Error fetching audio:', audioResponse.status)
      }
    } else {
      console.error('Authorization failed:', authResponse.status)
    }
  } catch (error) {
    console.error('Error in fetchAudio:', error)
  }
}

export const createAudioArchive = ({
  audioIds,
  serviceType,
  authToken
}: {
  audioIds: number[],
  serviceType: TConsoleMode,
  authToken: string
}) => {
  const zip = new JSZip()

  const audioUrls = serviceType === 'asr'
    ? audioIds.map(id =>
      urlAsr + '/records?token=' + authToken + '&session_id=' + id
    )
    : audioIds.map(id =>
      urlSynthesis + '/media?token=' + authToken + '&session_id=' + id
    )

  audioUrls.forEach((url, index) => {
    const fileName = `audio_${index + 1}.mp3`

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        zip.file(fileName, blob, { binary: true })
        if (index === audioUrls.length - 1) {
          zip.generateAsync({ type: 'blob' }).then(content => {
            const url = URL.createObjectURL(content)

            const link = document.createElement('a')
            link.href = url
            link.download = 'audio_archive.zip'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
      .catch(error => {
        console.error('Error fetching audio file:', error)
      })
  })
}
