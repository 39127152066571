import { SessionsListItem } from './sessionListItem'
import classes from '../../styles/statisticsConsole/console.module.scss'
import { useSelector } from '../../hooks/redux'


export const Sessions = () => {
    const sessions = useSelector((state) => state.console.sessions)
    
    return (
        <div className={classes.sessionsList}>
            {sessions.length !== 0 && sessions.map((item) => (
                <SessionsListItem key={item.id} item={item} />
                ))
            }
        </div>
    )
}
