import { FilterHeader } from '../../layouts/statisticsConsole/filterHeader'
import { Container } from '../../components/container'
import { Sessions } from '../../layouts/statisticsConsole/sessions'
import { SearchFeedback } from '../../layouts/statisticsConsole/searchFeedback'
import { useSelector } from '../../hooks/redux'

export const StatisticsConsole = () => {
  const sessionsLength = useSelector((state) => state.console.sessions.length)
  const loading = useSelector((state) => state.console.loading)

  return (
    <>
      <Container>
        <section>
          <FilterHeader />
          {sessionsLength && !loading
            ? <Sessions />
            : <SearchFeedback />
          }
        </section>
      </Container>
    </>
  )
}
