import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStateAdmin, TModalUser } from '../../../@types/admin'


const initialState: IStateAdmin = {
    selectedUsers: [],
    filters: {
        searchString: '',
        accessConfig: {
            access_console: false,
            access_asr: false,
            access_tts: false,
            access_admin: false
        }
    },
    loading: false,
    modal: {
        showModal: false,
        user: {
            auth_username: '',
            auth_token: '',
            access_console: false,
            access_asr: false,
            access_tts: false,
            access_admin: false
        }
    }
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        updateSearchString(state, action: PayloadAction<string>) {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchString: action.payload
                }
            }
        },
        updateSelectedUsersList(state, action: PayloadAction<number>) {
            const id = action.payload

            return {
                ...state,
                selectedUsers: state.selectedUsers.includes(id)
                    ? state.selectedUsers.filter(userId => id !== userId)
                    : [
                        ...state.selectedUsers,
                        action.payload
                    ]
            }
        },
        updateAccessConfigFilter(state, action) {
            const access = action.payload

            return {
                ...state,
                filters: {
                    ...state.filters,
                    accessConfig: {
                        ...state.filters.accessConfig,
                        ...access
                    }

                }
            }
        },
        setModalVisibility(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    showModal: action.payload
                }
            }
        },
        updateModalSettings(state, action: PayloadAction<Partial<TModalUser>>) {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    user: {
                        ...state.modal.user,
                        ...action.payload
                    }
                }
            }
        },
        setDefaultModalSettings(state) {
            return {
                ...state,
                modal: initialState.modal
            }
        }
    },
    extraReducers: (builder) => {

    }
})

const { actions, reducer } = adminSlice

export const {
    updateSearchString,
    updateSelectedUsersList,
    updateAccessConfigFilter,
    setModalVisibility,
    setDefaultModalSettings,
    updateModalSettings
} = actions

export default reducer
