import { memo } from 'react'
import classes from '../../../styles/tts/ttsModal.module.scss'
import { CustomButton } from '../../../components/customButton'
import { languageTranslate } from '../../asr/settings/config'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { TIcon } from '../../../components/customIcon/config'
import { useSelector, useDispatch } from '../../../hooks/redux'
import { TSex } from '../../../@types/tts'
import { initialFilterLanguages, initialSexFilter, ttsUpdateModalFilters } from '../../../store/slices/tts/ttsSlice'
import { useMediaQuery } from 'react-responsive'

import cn from 'classnames'

const TtsModalLanguageFilter = memo(() => {
  const dispatch = useDispatch()
  const currentLanguages = useSelector((state) => state.tts.filters.language)
  const isLaptopMobile = useMediaQuery({ query: '(max-width: 862px)' })

  const handleSetFilter = (val: string) => {
    if (currentLanguages.includes(val)) {
      const filtered = currentLanguages.filter((lng) => lng !== val)
      return dispatch(ttsUpdateModalFilters({ language: filtered.length === 0 ? initialFilterLanguages : filtered }))
    }

    dispatch(ttsUpdateModalFilters({ language: [...currentLanguages, val] }))
  }

  return (
    <div>
      <div className={classes.ttsFilterLineWrapper}>
        {initialFilterLanguages.map((lng) => (
          <CustomButton
            key={lng}
            onClick={() => handleSetFilter(lng)}
            className={cn(classes.ttsFilterBtn, currentLanguages.includes(lng) && classes.active)}
          >
            {!isLaptopMobile ? languageTranslate[lng] : languageTranslate[lng].slice(0, 3)}
          </CustomButton>
        ))}
      </div>
    </div>
  )
})

export const TtsModalFilters = memo(() => {
  const dispatch = useDispatch()
  const currentSex = useSelector((state) => state.tts.filters.sex)
  const isMobile = useMediaQuery({ query: '(max-width: 606px)' })

  const handleSetFilter = (val: TSex) => {
    if (currentSex.includes(val)) {
      const filtered = currentSex.filter((sex) => sex !== val)
      return dispatch(ttsUpdateModalFilters({ sex: filtered.length === 0 ? initialSexFilter : filtered }))
    }

    dispatch(ttsUpdateModalFilters({ sex: [...currentSex, val] }))
  }

  return (
    <div className={classes.ttsFiltersWrapper}>
      <TtsModalLanguageFilter />
      {!isMobile && <div className={classes.ttsFiltersSeparator}>|</div>}
      <div>
        <div className={classes.ttsFilterLineWrapper}>
          {initialSexFilter.map((sex) => (
            <CustomButton
              key={sex}
              onClick={() => handleSetFilter(sex)}
              className={cn(classes.ttsFilterBtn, currentSex.includes(sex) && classes.active)}
            >
              <CustomIcon icon={sex as TIcon} /> {sex === 'male' ? 'Мужской' : 'Женский'}
            </CustomButton>
          ))}
        </div>
      </div>
    </div>
  )
})

TtsModalFilters.displayName = 'ModalFilters'
