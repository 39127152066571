import { Main } from '../../layouts/asr/Main'
import Sidebar from '../../layouts/asr/Sidebar'

import classes from '../../styles/asr/asrSidebar.module.scss'
import headerClasses from '../../styles/header.module.scss'

import { AsrDropzone } from '../../components/asrDropzone'
import { useSelector } from '../../hooks/redux'
import { Container } from '../../components/container'

export const Asr = () => {
  const isStreamRecognition = useSelector((state) => state.asr.selectedAsrSettings.use_stream)
  const isRecordingAndUpload = useSelector((state) => state.asr.status === 'loading' || state.asr.status === 'pause')

  return (
    <div>
      <AsrDropzone disabled={!!isStreamRecognition || isRecordingAndUpload}>
        <div className={headerClasses.mainTitle}>
          <h1>Распознавание речи NLab&nbsp;Speech</h1>
        </div>
        <Container>
          <section className={classes.asrSection}>
            <Sidebar />
            <Main />
          </section>
        </Container>
      </AsrDropzone>
    </div>
  )
}
