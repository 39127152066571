import { useDispatch, useSelector } from "../../hooks/redux"
import { useEffect, useMemo } from "react"
import { UsersListItem } from "./usersListItem"
import { getUsers } from "../../store/slices/user/async"
import { filter } from "../../functions"
import classes from '../../styles/admin/usersList.module.scss'


export const UsersList = () => {
    const dispatch = useDispatch()
    const users = useSelector((state) => state.user.users)
    const authToken = useSelector((state) => state.user.user.auth_token)
    const selectedUsers = useSelector((state) => state.admin.selectedUsers)
    const filters = useSelector((state) => state.admin.filters)

    const usersList = useMemo(() => {
        let arr = users

        const {
            access_asr,
            access_tts,
            access_console,
            access_admin
        } = filters.accessConfig

        if (filters.searchString.length > 0) {
            arr = users?.filter((user) => filter(user.auth_username, filters.searchString) || filter(user.auth_token, filters.searchString))
        }

        if (access_admin || access_console || access_asr || access_tts) {
            arr = arr?.filter(user => {
                return (
                    (user.access_asr && access_asr) ||
                    (user.access_tts && access_tts) ||
                    (user.access_admin && access_admin) ||
                    (user.access_console && access_console)
                )
            })
        }

        return arr
    }, [users, filters])

    useEffect(() => {
        if (!users?.length) {
            dispatch(getUsers(authToken))
        }
    }, [authToken, dispatch, users?.length === 0])

    return (
        <div className={classes.usersList}>
            {
                usersList?.map((user) => {
                    return (
                        <UsersListItem
                            key={user.user_id}
                            config={user}
                            isSelected={selectedUsers.includes(user.user_id)}
                        />
                    )
                })
            }
        </div>
    )
}
