import { FC, ChangeEvent } from "react"
import classes from '../../styles/textField.module.scss'


export const TextField: FC<{
    localeText?: string
    value?: string
    handleClearInput?: () => void
    handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({
    localeText,
    value,
    handleClearInput,
    handleChangeInput
}) => {
    return (
        <div className={classes.textFieldWrapper}>
            <input
                type="text"
                className={classes.textFieldInput}
                placeholder={localeText || "Поиск"}
                value={value || ''}
                onChange={handleChangeInput}
            />
        </div>
    )
}