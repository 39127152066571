import { memo, useMemo } from 'react'

import { filter } from '../../functions'

import { useSelector } from '../../hooks/redux'
import { Message } from './Message'
import { AsrFilters } from './filter/filter'
import { NoResults } from '../../components/noResults'
import { StreamingText } from './streamingText'

import classes from '../../styles/asr/asrSidebar.module.scss'
import Scrollbar from 'react-scrollbars-custom'
import scrollClasses from '../../styles/customScrollbar.module.scss'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'

export const Main = memo(() => {
  const messages = useSelector((state) => state.asr.messages)
  const searchString = useSelector((state) => state.asr.searchString)
  const activeFilters = useSelector((state) => state.asr.activeFilters)
  const isStreaming = useSelector((state) => state.asr.stream.isStreaming)
  const isMediumScreen = useMediaQuery({ query: '(max-width: 1280px)' })
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1070px)' })

  const filteredMessages = useMemo(() => {
    let arr = messages

    if (searchString)
      arr = arr.filter(
        (item) => filter(item.textFilterd, searchString) || filter(item.textNotFiltred, searchString.replace(' ', ''))
      )
    if (activeFilters.language) arr = arr.filter((item) => activeFilters.language.includes(item.language))
    if (activeFilters.use_stream > -1)
      arr = arr.filter((item) => item.use_stream === activeFilters.use_stream)

    return arr
  }, [activeFilters, messages, searchString])

  return (
    <div className={cn(classes.asrRightSide, (messages.length || isStreaming) && classes.asrRightActive)}>
      {!isLaptopOrMobile && <AsrFilters />}
      <StreamingText />
      <div className={classes.asrContentRight}>
        {isMediumScreen ? (
          <>
            {filteredMessages.length ? (
              filteredMessages.map((item, index) => {
                return <Message key={item.id + index} item={item} searchString={searchString} />
              })
            ) : (
              <>{!!messages?.length && <NoResults />}</>
            )}
          </>
        ) : (
          <Scrollbar noScrollX className={scrollClasses.customScrollbar}>
            {filteredMessages.length ? (
              filteredMessages.map((item, index) => {
                return <Message key={item.id + index} item={item} searchString={searchString} />
              })
            ) : (
              <>{!!messages?.length && <NoResults />}</>
            )}
          </Scrollbar>
        )}
      </div>
    </div>
  )
})
