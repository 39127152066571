import { FC } from 'react'
import { DrawerProps } from '../@types/ui'
import classes from '../styles/drawer.module.scss'
import cn from 'classnames'
import { ReactPortal } from './reactPortal'
import { Backdrop } from './modal/backdrop'


export const Drawer: FC<DrawerProps> = ({ isOpen, onClose, children }) => {
    return (
        <ReactPortal>
            <>
                <Backdrop show={isOpen} onClick={onClose} />
                <div className={cn(classes.drawer, isOpen && classes.open)}>
                    {children}
                </div>
            </>
        </ReactPortal>
    )
}