import { CustomButton } from "../../components/customButton"
import { CustomIcon } from "../../components/customIcon/customIcon"
import { useDispatch, useSelector } from "../../hooks/redux"
import classes from '../../styles/admin/usersList.module.scss'
import ttsClasses from '../../styles/tts/ttsFilters.module.scss'
import cn from 'classnames'
import { updateAccessConfigFilter } from "../../store/slices/admin/adminSlice"


export const AdminFilters = () => {
    const dispatch = useDispatch()
    const accessConfig = useSelector((state) => state.admin.filters.accessConfig)

    return (
        <div className={ttsClasses.ttsSSMLListFilter}>
            <span className={ttsClasses.ttsFilterListTitle}>Доступы</span>
            <div className={ttsClasses.ttsSSMLListFilterBtns}>
                <CustomButton
                    viewType="outline"
                    className={cn(classes.accessButton, accessConfig.access_asr ? classes.active : classes.disabled)}
                    onClick={() => dispatch(updateAccessConfigFilter( {access_asr: !accessConfig.access_asr}))}
                >
                    ASR
                </CustomButton>
                <CustomButton
                    viewType="outline"
                    className={cn(classes.accessButton, accessConfig.access_tts ? classes.active : classes.disabled)}
                    onClick={() => dispatch(updateAccessConfigFilter( {access_tts: !accessConfig.access_tts}))}
                >
                    TTS
                </CustomButton>
                <CustomButton
                    viewType="outline"
                    className={cn(classes.accessButton, accessConfig.access_console ? classes.active : classes.disabled)}
                    onClick={() => dispatch(updateAccessConfigFilter( {access_console: !accessConfig.access_console}))}
                >
                    Консоль
                </CustomButton>
                <CustomButton
                    viewType="outline"
                    className={cn(classes.accessButton, accessConfig.access_admin ? classes.active : classes.disabled)}
                    onClick={() => dispatch(updateAccessConfigFilter( {access_admin: !accessConfig.access_admin}))}
                >
                    Админка
                </CustomButton>
            </div>
        </div>
    )
}
