import { useState, FC, ChangeEvent } from "react"
import { CustomIcon } from '../customIcon/customIcon'
import classes from '../../styles/textField.module.scss'
import loginFormClasses from '../../styles/loginForm.module.scss'


export const TokenField: FC<{
    value: string
    onChange: (e: ChangeEvent) => void
}> = ({
    value,
    onChange
}) => {
  const [showToken, setShowToken] = useState<boolean>(false)

  const toggleTokenVisibility = () => {
    setShowToken((p) => !p)
  }

    return (
        <div className={classes.textFieldWrapper}>
            <input
                className={classes.textFieldInput}
                type={showToken ? "text" : 'password'}
                placeholder="Токен"
                value={value}
                onChange={onChange}
            />
            {value !== '' && (
                <button onClick={toggleTokenVisibility}>
                    <CustomIcon icon={showToken ? 'hideIcon' : 'showIcon'} />
                </button>
            )}
        </div>
    )
}
