import React, { useEffect, useState } from 'react'

import { ReactMic } from 'react-mic'
import classes from '../../styles/asr/asrSidebar.module.scss'
import modalClasses from '../../styles/mobilePopup.module.scss'

import { useStreamRecord } from './useStreamRecord'
import { useMediaQuery } from 'react-responsive'
import { asrSetStatus } from '../../store/slices/asr/asrSlice'
import { Status } from '../../@types/asr'
import { asrUpload } from '../../store/slices/asr/async'
import { AsrSettings } from './settings/asrSettings'
import { useDispatch, useSelector } from '../../hooks/redux'
import cn from 'classnames'
import { StreamingRecordButton } from './streamingRecordButton'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { MobilePopup } from '../../components/mobilePopup'
import { CustomButton } from '../../components/customButton'

const Sidebar = () => {
  const { startListening } = useStreamRecord()
  const dispatch = useDispatch()
  const status = useSelector((state) => state.asr.status)
  const formData = useSelector((state) => state.asr.selectedAsrSettings)
  const isStreaming = useSelector((state) => state.asr.stream.isStreaming)
  const hasMessages = useSelector((state) => state.asr.messages.length > 0)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [showMobilePopup, setShowMobilePopup] = useState<boolean>(false)

  const handleSubmit = (data: any, event?: any) => {
    event?.preventDefault()
    dispatch(asrUpload(data))
  }

  const handleFileChange = (event: any) => {
    handleSubmit({ ...formData, audio_blob: event.target.files }, event)
    setShowMobilePopup(false)
  }

  const blobToFile = (theBlob: Blob, fileName: string) => {
    let b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    return theBlob as File
  }

  const handleClickRecord = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (formData.use_stream) {
      if (status === Status.start) {
        startListening()
      }
    } else {
      if (status === Status.pause) {
        dispatch(asrSetStatus({ status: Status.loading }))
      }
      if (status === Status.start) {
        dispatch(asrSetStatus({ status: Status.pause }))
      }
    }
  }

  useEffect(() => {
    Object.assign(window, { globalFormChange: formData })
  }, [formData])

  const recordStop = (data: any) => {
    if (!(window as any).globalFormChange.use_stream) {
      handleSubmit({ ...(window as any).globalFormChange, audio_blob: [blobToFile(data.blob, 'audio_blob')] })
    }
  }

  return (
    <>
      <div
        className={cn(
          classes.asrSidebarFormWrapper,
          (isStreaming || hasMessages) && classes.active,
          isStreaming && classes.formStreaming
        )}
      >
        <ReactMic
          record={status === 'pause'}
          onStop={recordStop}
          strokeColor="rgba(70, 156, 183, 0.8)"
          backgroundColor="#fff"
          className={cn(classes.reactMic)}
        />
        <div className={cn(classes.asrSidebarForm, isStreaming && classes.formStreaming)}>
          <form>
            <div className={classes.fileInputWrapper}>
              <input
                type="file"
                name="audio_blob"
                multiple={true}
                id="file"
                onChange={handleFileChange}
                value={formData.audio_blob}
              />
              {formData.use_stream ? (
                <div
                  {...(isLaptopOrMobile && {
                    onClick: () => {
                      setShowMobilePopup(true)
                    },
                  })}
                  className={classes.inputTitle}
                >
                  <span>Нажмите на&nbsp;микрофон для&nbsp;начала&nbsp;записи</span>
                  <PortalTooltip
                    showOnMobile
                    tooltipContent="Потоковый режим позволяет одновременно отправлять аудио на распознавание и получать результаты распознавания в рамках одного соединения. В потоковом режиме аудио с микрофона постоянно распознается. При паузе в речи текст начинается с нового абзаца."
                  >
                    <CustomIcon icon="info" />
                  </PortalTooltip>
                </div>
              ) : (
                <div
                  {...(isLaptopOrMobile && {
                    onClick: () => {
                      setShowMobilePopup(true)
                    },
                  })}
                  className={classes.inputTitle}
                >
                  <span>
                    Нажмите на&nbsp;микрофон для&nbsp;записи,
                    <br />
                    перетащите файл или&nbsp;
                    <label htmlFor={(!isLaptopOrMobile && 'file') || ''}>загрузите&nbsp;тут</label>
                  </span>
                  <PortalTooltip
                    showOnMobile={false}
                    tooltipContent="Доступные кодеки аудио: LPCM, Opus, MP3. Максимальная длительность – 3 часа, максимальный размер файла – 1 Гб. Также поддерживается обработка видео файлов популярных форматов: WebM, WAV, MP3, OGG, ACC, FLAC, AU, M4A."
                  >
                    <CustomIcon icon="info" />
                  </PortalTooltip>
                </div>
              )}
              <div className={classes.formRecordBtnWrapper} onClick={handleClickRecord}>
                <StreamingRecordButton isRecording={status === 'pause'} isLoading={status === 'loading'} />
              </div>
            </div>
            <AsrSettings />
          </form>
        </div>
      </div>
      {isLaptopOrMobile && (
        <MobilePopup show={showMobilePopup} handleClose={() => setShowMobilePopup(false)}>
          <div className={modalClasses.mobilePopupWrapper}>
            <div className={modalClasses.mobilePopupSubtitle}>
              Доступные кодеки аудио: LPCM, Opus, MP3. Максимальная длительность – 3 часа, максимальный размер файла – 1
              Гб. Также поддерживается обработка видео файлов популярных форматов: WebM, WAV, MP3, OGG, ACC, FLAC, AU,
              M4A.
            </div>
            <div className={modalClasses.mobilePopupButtonGroup}>
              {!formData.use_stream ? (
                <CustomButton className={modalClasses.download}>
                  <label htmlFor="file">Загрузить</label>
                  <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                </CustomButton>
              ) : null}
              <CustomButton className={modalClasses.close} onClick={() => setShowMobilePopup(false)}>
                Закрыть
              </CustomButton>
            </div>
          </div>
          {/* {stringState === 'filter' ? <TtsListFilters /> : <TtsListSsmlMarkupFilter />} */}
        </MobilePopup>
      )}
    </>
  )
}

export default Sidebar
