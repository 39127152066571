import { FC } from "react"
import { useDispatch } from "../../hooks/redux"
import { updateAccess, deleteUser } from "../../store/slices/user/userSlice"
import { updateSelectedUsersList } from "../../store/slices/admin/adminSlice"
import { Checkbox } from "../../components/checkbox/checkbox"
import { CustomButton } from "../../components/customButton"
import { TextField } from "../../components/textField/textField"
import { TokenField } from "../../components/textField/tokenField"
import { CustomIcon } from "../../components/customIcon/customIcon"
import classes from '../../styles/admin/usersList.module.scss'
import { IUser } from "../../@types/user"
import cn from 'classnames'


export const UsersListItem: FC<{ 
    config: IUser, 
    isSelected: boolean
}> = ({ 
    config,
    isSelected
}) => {
    const dispatch = useDispatch()

    const {
        auth_username,
        auth_token,
        user_id,
        access_asr,
        access_tts,
        access_console,
        access_admin
    } = config

    const toggleAccess = (permissions: {
        access_asr?: boolean,
        access_tts?: boolean,
        access_console?: boolean,
        access_admin?: boolean
    }) => {
        dispatch(updateAccess({ user_id, ...permissions }))
    }

    const removeUser = () => {
        dispatch(deleteUser(user_id))
    }

    const selectUser = () => {
        dispatch(updateSelectedUsersList(user_id))
    }

    return (
        <div className={classes.userSettings}>
            <div className={classes.userInfo}>
                <Checkbox isChecked={isSelected} handleCheckboxChange={selectUser} />
                <TextField 
                    localeText="Имя пользователя"
                    value={auth_username}
                />
                
                <TokenField value={auth_token} onChange={() => {}} />
            </div>
            <div className={classes.userAccesses}>
                <CustomButton
                    onClick={() => toggleAccess({ access_asr: !access_asr })}
                    className={cn(classes.accessButton, access_asr ? classes.active : classes.disabled)}
                    style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                >
                    <CustomIcon icon="asrLogo" /> ASR
                </CustomButton>
                <CustomButton
                    onClick={() => toggleAccess({ access_tts: !access_tts })}
                    className={cn(classes.accessButton, access_tts ? classes.active : classes.disabled)}
                    style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                >
                    <CustomIcon icon="ttsLogo" /> TTS
                </CustomButton>
                <CustomButton
                    onClick={() => toggleAccess({ access_console: !access_console })}
                    className={cn(classes.accessButton, access_console ? classes.active : classes.disabled)}
                    style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                >
                    <CustomIcon icon="consoleLogo" /> Консоль
                </CustomButton>
                <CustomButton
                    onClick={() => toggleAccess({ access_admin: !access_admin })}
                    className={cn(classes.accessButton, access_admin ? classes.active : classes.disabled)}
                    style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                >
                    <CustomIcon icon="settings" /> Админка
                </CustomButton>
            </div>
            <CustomButton
                onClick={removeUser}
                className={classes.deleteUserBtn}
            >
                <CustomIcon icon="delete" />
            </CustomButton>
        </div>
    )
}
