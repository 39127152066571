import axios from 'axios'

import { store } from './store'

export type Body = Record<string, unknown>

export const authPost = (url: string, body: Body) => {
  const token = store.getState().user.user.auth_token || ''
  return axios.post(`${url}`, body, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  })
}

export const get = (url: string) => {
  return axios.get(`${url}`)
}

export const upload = (url: string, body: FormData) => {
  const token = store.getState().user.user.auth_token || ''

  const config = {
    headers: {
      // 'content-type': 'multipart/form-data',
      Authorization: `Basic ${token}`,
    },
  }

  return axios.post(`${url}`, body, config)
}
