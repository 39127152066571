import { store } from '../store'
import { addNotify } from '../store/slices/notify/notifySlice'

export const convertFromMsToSec = (ms: number, offFixed?: boolean) => {
  const seconds = (ms % 60000) / 1000
  if (offFixed) return seconds

  return +seconds.toFixed(0)
}

export const convertFromSecondsToTime = (sec: number) => {
  if (!sec) return '00:00'

  let minutes: string | number = Math.floor(sec / 60)
  let secs: string | number = sec % 60

  if (minutes < 10) minutes = '0' + minutes
  if (secs < 10) secs = '0' + secs

  return minutes + ':' + secs
}

export const copyText = async (textToCopy: string) => {
  try {
    if (!textToCopy) return new Error('Не удалось скопировать текст')
    await navigator.clipboard.writeText(textToCopy)
    store.dispatch(addNotify({ text: 'Текст успешно скопирован' }))
  } catch (err) {
    store.dispatch(addNotify({ text: 'Текст не удалось скопировать' }))
  }
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const nearestValue = (arr: number[], val: number) => {
  return arr.reduce((nearest, num) => (Math.abs(num - val) >= Math.abs(nearest - val) && nearest < num ? nearest : num))
}

export const formatSeconds = (count: number) => {
  if (count === 1) {
    return 'секунду'
  } else if (count > 1 && count < 5) {
    return 'секунды'
  } else {
    return 'секунд'
  }
}

export const formatSessions = (count: number) => {
  const getDeclension = (number: number, forms: string[]) => {
    const lastTwoDigits = number % 100
    const lastDigit = number % 10

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
      return forms[2]
    }
    if (lastDigit === 1) {
      return forms[0]
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return forms[1]
    }
    return forms[2]
  }

  const forms = ['сессия', 'сессии', 'сессий']

  return `${count} ${getDeclension(count, forms)}`
}
