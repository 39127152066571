import { FC, memo, useState } from 'react'
import { asrHideShowText } from '../../../store/slices/asr/asrSlice'
import { CustomButton } from '../../../components/customButton'
import { useSelector, useDispatch } from '../../../hooks/redux'
import { CustomIcon } from '../../../components/customIcon/customIcon'

import classes from '../../../styles/filter.module.scss'
import cn from 'classnames'
import { SwitchCheckbox } from '../../../components/checkbox/switchCheckbox'

const textFilterButtons = [
  { name: 'Фильтрованный текст', key: 'textFiltredShow' },
  { name: 'Распознанный текст', key: 'textNotFiltredShow' },
]

export const ShowHideTextFilter: FC<{ isMobile?: boolean }> = memo(({ isMobile }) => {
  const dispatch = useDispatch()
  const message = useSelector((state) => state.asr.messages?.[0])
  const [activeButtons, setActiveButtons] = useState({
    textFiltredShow: message?.textFiltredShow !== undefined ? message?.textFiltredShow : true,
    textNotFiltredShow: message?.textNotFiltredShow !== undefined ? message?.textNotFiltredShow : true,
  })

  const handleHideText = (key: string, value: boolean) => {
    if (key === 'textFiltredShow')
      dispatch(
        asrHideShowText({
          key,
          value,
        })
      )

    if (key === 'textNotFiltredShow') {
      dispatch(
        asrHideShowText({
          key,
          value,
        })
      )
    }
  }

  const handleClick = (key: string) => {
    handleHideText(key, !(message && (message as any)?.[key]))
    setActiveButtons((p) => ({ ...p, [key]: !(p as any)[key] }))
  }

  return (
    <>
      {isMobile && (
        <div className={classes.filterTextButtonMobileWrapper}>
          {textFilterButtons.map(({ key, name }) => (
            <div className={classes.filterTextButtonMobile} key={name}>
              <span>{name}</span>
              <SwitchCheckbox checked={(activeButtons as any)[key]} onChange={() => handleClick(key)} name={name} />
            </div>
          ))}
        </div>
      )}
      {!isMobile && (
        <div className={classes.filterTextButtons}>
          <span className={classes.filterTextTitle}>Показать</span>
          {textFilterButtons.map(({ key, name }) => {
            return (
              <CustomButton
                key={key}
                className={cn((activeButtons as any)[key] && classes.filterTextButtonActive)}
                viewType="outline"
                onClick={() => handleClick(key)}
              >
                {name} <CustomIcon icon={(activeButtons as any)[key] ? 'showIcon' : 'hideIcon'} />
              </CustomButton>
            )
          })}
        </div>
      )}
    </>
  )
})

ShowHideTextFilter.displayName = 'ShowHideTextFilter'
