import { memo, useMemo, useRef, useState, useEffect, FC } from 'react'
import cn from 'classnames'

import { ttsSetRemovePickedVoice, ttsUpdatePickedVoices, ttsSetEmotionVoice } from '../../store/slices/tts/ttsSlice'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { EmotionListConfig } from './ttsModal/ttsModalVoices'
import { CustomDialog } from '../../components/customDialog'
import { LanguageIcons } from '../../components/languageIcons'
import { languageTranslate } from '../asr/settings/config'
import { useSelector, useDispatch } from '../../hooks/redux'
import { TtsModal } from './ttsModal/ttsModal'
import { EmotionList } from './emotionList'
import { IVoice } from '../../@types/tts'
import classes from '../../styles/tts/ttsSidebarVoices.module.scss'
import msgClasses from '../../styles/tts/ttsMessages.module.scss'
import modalClasses from '../../styles/tts/ttsModal.module.scss'
import addButton from '../../assets/icons/addVoice.svg'
import closeButton from '../../assets/icons/close.svg'
import star from '../../assets/icons/star.svg'
import { useMediaQuery } from 'react-responsive'

const SidebarVoicesTooltipContent: FC<{ voice: IVoice }> = ({ voice }) => {
  const { title, languages, sex, emotion } = voice
  return (
    <div className={classes.ttsTooltipContent}>
      <div className={classes.tooltipOtherInfo}>
        <span className={cn(classes.tooltipSex, sex === 'female' && classes.tooltipFemale)}>
          <CustomIcon icon={sex} />
        </span>
      </div>
      <LanguageIcons languages={languages} />
      <div className={classes.tooltipName}>
        {title}
        {emotion && <img className={modalClasses.ttsVoiceEmotionMark} src={star} alt="emotion" />}
      </div>
    </div>
  )
}

const TtsOverflowVoices: FC<{
  showDialog: boolean
  voices: IVoice[]
  handleActiveVoice: (voice: IVoice) => () => void
  toggleShowDialog: (state: boolean) => void
  handleActiveEmotion: (name: string, voice: IVoice) => void
}> = ({ voices, handleActiveEmotion, handleActiveVoice, showDialog, toggleShowDialog }) => {
  const [hoveredVoice, setHoveredVoice] = useState<IVoice | null>(null)
  const [selectedVoice, setSelectedVoice] = useState<IVoice>(voices[0])

  const handleMouseEnter = (voice: IVoice) => {
    setHoveredVoice(voice)
  }

  const handleMouseLeave = () => {
    setHoveredVoice(null)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div className={classes.ttsOverflowVoicesTooltip}>
        {voices?.map((voice, index) => {
          return (
            <div
              onMouseEnter={() => handleMouseEnter(voice)}
              onMouseLeave={handleMouseLeave}
              key={voice.name + index + 'sidebarOverflow'}
              className={classes.ttsOverflowVoiceItem}
            >
              <div className={classes.ttsAvatarWraper}>
                <div className={msgClasses.ttsAvatar}>
                  <img draggable={false} src={voice.avatar} alt='' />
                </div>
                <div className={classes.name}>
                  {voice.title}
                  {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt="emotion" />}
                </div>
              </div>
              <div className={classes.ttsOverflowVoicesOtherInfo}>
                {/* {voice.emotion && (
                  <button
                    onClick={() => {
                      setSelectedVoice(voice)
                      toggleShowDialog(!showDialog)
                    }}
                    className={classes.ttsOverflowVoiceEmotion}
                  >
                    <CustomDialog
                      handleClick={() => toggleShowDialog(!showDialog)}
                      showDialog={showDialog}
                      placement="bottom-middle"
                      dialogContent={
                        <EmotionList
                          onClick={(name) => handleActiveEmotion(name, selectedVoice)}
                          selectedEmotion={selectedVoice.emotion}
                          config={EmotionListConfig}
                        />
                      }
                    >
                      <img
                        width={20}
                        height={20}
                        src={EmotionListConfig.find((item) => item.name === voice.emotion)?.url}
                        alt={voice.emotion}
                      />
                    </CustomDialog>
                  </button>
                )} */}
                {hoveredVoice !== voice ? (
                  <LanguageIcons languages={voice.languages} />
                ) : (
                  <button
                    className={classes.ttsOverflowVoicesCrossBtn}
                    onClick={() => {
                      handleActiveVoice(voice)()
                    }}
                  >
                    <CustomIcon icon="cross" />
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {/* {selectedVoice && showDialog && (
        <EmotionList
          onClick={(name) => handleActiveEmotion(name, selectedVoice)}
          selectedEmotion={selectedVoice.emotion}
          config={EmotionListConfig}
        />
      )} */}
    </div>
  )
}

export const TtsSidebarVoices = memo(() => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const voices = useSelector((state) => state.tts.voices)
  const ttsMessages = useSelector((state) => state.tts.messages)
  const isSidebarSmall = useSelector((state) => state.tts.messages.length > 0)
  const [showPopup, setShowPopup] = useState(false)
  const [prevVoices, setPrevVoices] = useState(JSON.parse(JSON.stringify(voices)))
  const [hoveredVoice, setHoveredVoice] = useState<IVoice | null>(null)
  const [showEmotionDialog, setShowEmotionDialog] = useState(false)
  const [showVoicesOverflowDialog, setShowVoicesOverflowDialog] = useState<boolean>(false)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleCloseOpenPopup = () => {
    if (showPopup) {
      dispatch(ttsUpdatePickedVoices(prevVoices))
    } else {
      setPrevVoices(JSON.parse(JSON.stringify(voices)))
    }
    setShowPopup((p) => !p)
  }

  const pickedVoices = useMemo(() => voices.filter((item) => item.picked), [voices])

  const [maxVoicesInSidebar, setMaxVoicesInSidebar] = useState<number>(pickedVoices.length)

  const handleActiveVoice = (voice: IVoice) => () => {
    dispatch(ttsSetRemovePickedVoice({ name: voice.name }))
  }

  const handleMouseEnter = (voice: IVoice | null) => {
    setHoveredVoice(voice)
  }

  const handleMouseLeave = () => {
    setHoveredVoice(null)
  }

  const handleActiveEmotion = (name: string, voice: IVoice) => {
    setShowEmotionDialog(!showEmotionDialog)
    setShowEmotionDialog(false)
    dispatch(ttsSetEmotionVoice({ name: voice.name, emotion: name }))
  }

  useEffect(() => {
    const handleResize = () => {
      if (!ref.current || !ref.current.children.length) return
      const sidebarWidth = ref.current.offsetWidth
      const children = ref.current.children
      let totalWidth = 0

      for (let i = 0; i < children.length; i++) {
        const element = children[i] as HTMLElement
        const offsetWidth = element.offsetWidth
        totalWidth += offsetWidth + 25
      }

      const overflowBtnWidth = isSidebarSmall || isLaptopOrMobile ? 45 : 120
      const averageItemWidth = totalWidth / children.length

      setMaxVoicesInSidebar(Math.floor((sidebarWidth - overflowBtnWidth) / averageItemWidth))
    }

    setTimeout(handleResize, 500)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [pickedVoices, isSidebarSmall, isLaptopOrMobile])

  const visiblePickedVoices = maxVoicesInSidebar > 0 ? pickedVoices.slice(0, maxVoicesInSidebar) : []

  return (
    <div className={cn(classes.ttsSidebarHeaderWrapper, (isSidebarSmall || isLaptopOrMobile) && classes.sidebarSmall)}>
      <button onClick={handleCloseOpenPopup} className={cn(classes.ttsVoiceItem, classes.ttsAddVoiceBtn)}>
        <img src={addButton} alt="" />
      </button>
      <div ref={ref} className={classes.ttsSidebarVoicesWrapper}>
        {visiblePickedVoices?.map((voice, index) => {
          const languages = voice.languages
            .map((lng) => languageTranslate[lng].toLowerCase() ?? lng.toLowerCase())
            .join(', ')

          return (
            <div key={index + voice.name + 'sidebar'}>
              <PortalTooltip
                classes={{ contentClassName: classes.voiceTooltipContent }}
                placement="bottom-right"
                tooltipContent={<SidebarVoicesTooltipContent voice={voice} />}
                hideTooltip={hoveredVoice !== voice || !ttsMessages?.length || showEmotionDialog}
              >
                <div
                  key={voice.name + index + 'sidebar'}
                  className={cn(classes.ttsVoiceItem, (isSidebarSmall || isLaptopOrMobile) && classes.ttsItemCollapsed)}
                  onMouseEnter={() => handleMouseEnter(voice)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    // if (isLaptopOrMobile && voice?.emotion) {
                    //   setShowEmotionDialog(!showEmotionDialog)
                    // }
                  }}
                >
                  <div
                    style={{
                      padding: isSidebarSmall || isLaptopOrMobile ? 0 : 4,
                    }}
                    className={classes.ttsVoiceAvatarWrapper}
                  >
                    <div className={classes.ttsVoiceAvatar}>
                      <img draggable={false} src={voice.avatar} alt='' />
                    </div>
                  </div>
                  {voice.emotion && (
                    <button className={classes.ttsVoiceEmotion}>
                      <CustomDialog
                        classes={{ contentClassName: classes.ttsEmotionDialog }}
                        handleClick={() => setShowEmotionDialog(!showEmotionDialog)}
                        showDialog={showEmotionDialog}
                        placement="bottom-middle"
                        dialogContent={
                          <EmotionList
                            onClick={(name) => handleActiveEmotion(name, voice)}
                            selectedEmotion={voice.emotion}
                            config={EmotionListConfig}
                          />
                        }
                      >
                        <img
                          width={20}
                          height={20}
                          src={EmotionListConfig.find((item) => item.name === voice.emotion)?.url}
                          alt={voice.emotion}
                        />
                      </CustomDialog>
                    </button>
                  )}
                  <div
                    className={cn(
                      classes.ttsVoiceContent,
                      (isSidebarSmall || isLaptopOrMobile) && classes.ttsVoiceContentCollapsed
                    )}
                  >
                    <div className={classes.name}>
                      {voice.title}
                      {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt="emotion" />}
                    </div>
                    <div className={classes.languages}>{languages.charAt(0).toUpperCase() + languages.slice(1)}</div>
                  </div>
                  {hoveredVoice === voice && (
                    <button
                      onClick={() => {
                        handleActiveVoice(voice)()
                      }}
                      className={classes.ttsCloseVoiceBtn}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  )}
                </div>
              </PortalTooltip>
            </div>
          )
        })}
        {pickedVoices.length !== visiblePickedVoices.length && (
          <CustomDialog
            triangle
            classes={{ contentClassName: classes.ttsOverflowVoiceContent }}
            placement="bottom-middle"
            showDialog={showVoicesOverflowDialog}
            handleClick={() => {
              setShowVoicesOverflowDialog(!showVoicesOverflowDialog)
              setShowEmotionDialog(false)
            }}
            dialogContent={
              <TtsOverflowVoices
                showDialog={showEmotionDialog}
                handleActiveEmotion={handleActiveEmotion}
                handleActiveVoice={handleActiveVoice}
                toggleShowDialog={(state) => setShowEmotionDialog(state)}
                voices={pickedVoices.slice(maxVoicesInSidebar, pickedVoices.length)}
              />
            }
          >
            <div className={cn(classes.ttsVoiceItem, classes.ttsSidebarOverflowBtn)}>
              +{pickedVoices.length - visiblePickedVoices.length}
            </div>
          </CustomDialog>
        )}
      </div>
      <TtsModal
        prevVoices={prevVoices}
        handleClose={handleCloseOpenPopup}
        showPopup={showPopup}
        confirmChanges={() => setShowPopup(false)}
      />
    </div>
  )
})

TtsSidebarVoices.displayName = 'TtsVoices'
