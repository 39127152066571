import React, { useState } from 'react'
import { OutClick } from '../outClick'
import { CustomIcon } from '../customIcon/customIcon'
import { ReactPortal } from '../reactPortal'
import classes from '../../styles/dataRangePicker.module.scss'
import { Calendar } from './Calendar'
import { formatDate } from '../../functions/date'

interface DateRangePickerProps {
  onChangeStartDate: (startDate: Date | null) => void,
  onChangeEndDate: (startDate: Date | null) => void,
  localeText: string,
  startDate: Date | null,
  endDate: Date | null
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChangeStartDate,
  onChangeEndDate,
  localeText,
  startDate,
  endDate
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [startMonth, setStartMonth] = useState(new Date())
  const [endMonth, setEndMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)))
  const [selecting, setSelecting] = useState<'start' | 'end'>('start')
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null)

  const handleMouseEnter = (day: Date) => {
    setHoveredDay(day)
  }

  const handleMouseLeave = () => {
    setHoveredDay(null)
  }

  const handleCloseOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const handleDateChange = (date: Date) => {
    if (selecting === 'start') {
      onChangeStartDate(date)
      onChangeEndDate(null)
      setSelecting('end')
    } else {
      if (startDate && date < startDate) {
        onChangeEndDate(startDate)
        onChangeStartDate(date)
      } else {
        onChangeEndDate(date)
      }
      setSelecting('start')
      setIsOpen(false)
    }
  }

  const handleStartMonthChange = (month: Date) => {
    setStartMonth(month)
  }

  const handleEndMonthChange = (month: Date) => {
    setEndMonth(month)
  }

  const isNextMonth = (start: Date, end: Date) => {
    const nextMonth = new Date(start)
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    return nextMonth.getFullYear() === end.getFullYear() && nextMonth.getMonth() === end.getMonth()
  }

  return (
    <>
      <div onClick={handleCloseOpen} className={classes.dateRangePickerWrapper}>
        {!((startDate && endDate) || isOpen)
          ? <span>{localeText}</span>
          : <span>{`${startDate ? formatDate(startDate, 'DD.MM.YYYY') : '__.__.____'} - ${endDate ? formatDate(endDate, 'DD.MM.YYYY') : '__.__.____'}`}</span>
        }
        <CustomIcon icon='calendar' />
      </div>
      {isOpen && (
        <ReactPortal>
          <OutClick onClick={handleCloseOpen}>
            <div className={classes.dateRangePickerCalendar}>
              <div className={classes.initialCalendar}>
                <Calendar
                  selectDate={handleDateChange}
                  currentMonth={startMonth}
                  onMonthChange={handleStartMonthChange}
                  disableNextButton={isNextMonth(startMonth, endMonth)}
                  rangeStart={startDate}
                  rangeEnd={endDate}
                  hoveredDay={hoveredDay}
                  handleDayMouseEnter={(date) => handleMouseEnter(date)}
                  handleDayMouseLeave={handleMouseLeave}
                  disabledMonth={endMonth.getMonth()}
                />
              </div>
              <div>
                <Calendar
                  selectDate={handleDateChange}
                  currentMonth={endMonth}
                  onMonthChange={handleEndMonthChange}
                  disablePrevButton={isNextMonth(startMonth, endMonth)}
                  rangeStart={startDate}
                  rangeEnd={endDate}
                  hoveredDay={hoveredDay}
                  handleDayMouseEnter={(date) => handleMouseEnter(date)}
                  handleDayMouseLeave={handleMouseLeave}
                  disabledMonth={startMonth.getMonth()}
                />
              </div>
            </div>
          </OutClick>
        </ReactPortal>
      )}
    </>
  )
}
