import { useSelector, useDispatch } from '../../../hooks/redux'
import { useMemo, useState } from 'react'
import { IVoice } from '../../../@types/tts'
import { ttsSetRemovePickedVoice, ttsSetEmotionVoice } from '../../../store/slices/tts/ttsSlice'
import classes from '../../../styles/tts/ttsModal.module.scss'
import star from '../../../assets/icons/star.svg'
import checked from '../../../assets/icons/checked.svg'
import cn from 'classnames'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { PlayerV2 } from '../../../components/player/playerV2'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { CustomDialog } from '../../../components/customDialog'
import flirtEmoji from '../../../assets/images/emoji/flirt.png'
import disappointmentEmoji from '../../../assets/images/emoji/disappointment.png'
import sadnessEmoji from '../../../assets/images/emoji/sadness.png'
import fearEmoji from '../../../assets/images/emoji/fear.png'
import curiosityEmoji from '../../../assets/images/emoji/curiosity.png'
import surpriseEmoji from '../../../assets/images/emoji/surprise.png'
import disgustEmoji from '../../../assets/images/emoji/disgust.png'
import evilEmoji from '../../../assets/images/emoji/evil.png'
import goodEmoji from '../../../assets/images/emoji/good.png'
import neutralEmoji from '../../../assets/images/emoji/neutral.png'
import { EmotionList } from '../emotionList'
import { LanguageIcons } from '../../../components/languageIcons'

export type EmotionListItemType = {
  name: string
  tittle: string
  url: string
}

export const EmotionListConfig = [
  {
    name: 'flirt',
    tittle: 'Флирт',
    url: flirtEmoji,
  },
  {
    name: 'disappointment',
    tittle: 'Разочарование',
    url: disappointmentEmoji,
  },
  {
    name: 'sadness',
    tittle: 'Грусть',
    url: sadnessEmoji,
  },
  {
    name: 'fear',
    tittle: 'Испуг',
    url: fearEmoji,
  },
  {
    name: 'curiosity',
    tittle: 'Любопытство',
    url: curiosityEmoji,
  },
  {
    name: 'surprise',
    tittle: 'Удивление',
    url: surpriseEmoji,
  },
  {
    name: 'disgust',
    tittle: 'Раздражение',
    url: disgustEmoji,
  },
  {
    name: 'evil',
    tittle: 'Злость',
    url: evilEmoji,
  },
  {
    name: 'good',
    tittle: 'Радость',
    url: goodEmoji,
  },
  {
    name: 'neutral',
    tittle: 'Нейтральная',
    url: neutralEmoji,
  },
]

export const ModalVoices = () => {
  const dispatch = useDispatch()
  const voices = useSelector((state) => state.tts.voices)
  const ttsFilters = useSelector((state) => state.tts.filters)
  const [showEmotionDialog, setShowEmotionDialog] = useState(false)

  const handleActiveVoice = (voice: IVoice) => () => {
    dispatch(ttsSetRemovePickedVoice({ name: voice.name }))
  }

  const handleActiveEmotion = (name: string, voice: IVoice) => {
    setShowEmotionDialog(!showEmotionDialog)
    setShowEmotionDialog(false)
    dispatch(ttsSetEmotionVoice({ name: voice.name, emotion: name }))
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation() // Останавливаем всплытие события, чтобы предотвратить скрытие модалки
  }

  const filteredVoices = useMemo(() => {
    let arr = voices

    if (ttsFilters?.sex?.length) {
      arr = arr.filter((item) => ttsFilters.sex.includes(item.sex))
    }

    if (ttsFilters?.language?.length) {
      arr = arr.filter((item) => ttsFilters.language.includes(item.languages[0]))
    }

    return arr
  }, [voices, ttsFilters])

  return (
    <div className={classes.ttsModalVoicesWrapper} onTouchMove={handleTouchMove}>
      {filteredVoices.map((voice, index) => {
        // const languages = voice.languages.map((lng) => (lng === 'ru' ? 'РУ' : lng.toUpperCase())).join(', ')
        return (
          <div
            key={voice.name + index}
            onClick={handleActiveVoice(voice)}
            className={cn(
              classes.ttsModalVoiceItem,
              voice.picked && classes.ttsPickedVoice,
              voice.sex === 'female' && classes.ttsFemaleVoice
            )}
          >
            {voice.picked && (
              <div className={classes.ttsModalEmptyCheckbox}>
                <img src={checked} alt="checked" />
              </div>
            )}
            {/* {voice.tooltip && (
              <PortalTooltip
                stayTooltipOnHover={true}
                classes={{ childrenWrapperClassName: classes.ttsModalTooltip }}
                placement="top-middle"
                showOnMobile
                tooltipContent={tooltip}
              >
                <CustomIcon icon="info" />
              </PortalTooltip>
            )} */}
            <div className={classes.ttsModalVoiceAvatar}>
              <img src={voice.avatar} alt={voice.name} />
            </div>
            {
              voice.previewAudio && (<div onClick={(e) => e.stopPropagation()} className={classes.playerModal}>
                <PlayerV2 url={voice.previewAudio} />
              </div>)
            }

            <div className={classes.ttsModalVoiceContent}>
              <div className={classes.ttsModalVoiceName}>
                <span>{voice.title}</span>
                {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt="emotion" />}
              </div>
              <div className={classes.ttsModalInfoIcons}>
                <div className={classes.ttsModalOtherContent}>
                  <CustomIcon icon={voice.sex} />
                </div>
                <LanguageIcons languages={voice.languages} />
              </div>
              {voice.emotion && (
                <CustomDialog
                  handleClick={() => setShowEmotionDialog(!showEmotionDialog)}
                  showDialog={showEmotionDialog}
                  dialogContent={
                    <EmotionList
                      onClick={(name) => handleActiveEmotion(name, voice)}
                      selectedEmotion={voice.emotion}
                      config={EmotionListConfig}
                    />
                  }
                >
                  {
                    <img
                      width={22}
                      height={22}
                      src={EmotionListConfig.find((item) => item.name === voice.emotion)?.url}
                      alt={voice.emotion}
                    />
                  }
                </CustomDialog>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
