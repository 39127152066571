import classes from '../../styles/tts/ttsSidebar.module.scss'

export const TtsSidebarHeader = () => {

  return (
    <div className={classes.ttsSidebarHeader}>
      <div className={classes.ttsInfoText}>
        Выберите один или несколько голосов, введите текст и нажмите «Синтезировать»
      </div>
    </div>
  )
}
