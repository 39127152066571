import { useState, MouseEvent } from "react"
import { ReactPortal } from "../../components/reactPortal"
import { Modal } from "../../components/modal/modal"
import { useSelector, useDispatch } from "../../hooks/redux"
import { CustomButton } from "../../components/customButton"
import { CustomIcon } from "../../components/customIcon/customIcon"
import ttsClasses from "../../styles/tts/ttsModal.module.scss"
import ttsSidebarClasses from "../../styles/tts/ttsSidebar.module.scss"
import classes from "../../styles/admin/adminModal.module.scss"
import usersListClasses from "../../styles/admin/usersList.module.scss"
import {
  setModalVisibility,
  setDefaultModalSettings,
  updateModalSettings
} from "../../store/slices/admin/adminSlice"
import cn from "classnames"
import { TModalUser } from "../../@types/admin"
import { createUser } from "../../store/slices/user/async"
import { addNotify } from "../../store/slices/notify/notifySlice"


export const AdminModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector((state) => state.admin.modal)
  const auth_token = useSelector((state) => state.user.user.auth_token)
  const [showToken, setShowToken] = useState<boolean>(false)

  const toggleTokenVisibility = (e: MouseEvent) => {
    e.preventDefault()
    setShowToken((p) => !p)
  }

  const handleCloseModal = () => {
    dispatch(setModalVisibility(false))
    dispatch(setDefaultModalSettings())
  }

  const handleChangeProperty = (property: Partial<TModalUser>) => {
    dispatch(updateModalSettings(property))
  }

  const addUser = () => {
    try {
      const {
        auth_token: token,
        auth_username: name,
        ...rest
      } = modal.user;

      const data = {
        token,
        name,
        auth_token,
        ...rest
      }

      const res = dispatch(createUser(data))
      handleCloseModal()

      return res
    } catch (e) {
      // dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    }
  }

  return (
    <ReactPortal>
      <Modal
        classes={{ modalContent: cn(ttsClasses.ttsModal, ttsClasses.TtsModalSettings) }}
        title="Новый пользователь"
        isOpen={modal.showModal}
        handleClose={handleCloseModal}
      >
        <div className={ttsClasses.ttsModalContent}>
          <form autoComplete="off" className={classes.newUserInfo}>
            <input
              value={modal.user.auth_username}
              className={classes.adminModalInput}
              placeholder="Имя пользователя"
              type="text"
              onChange={(e) => handleChangeProperty({ auth_username: e.target.value })}
            />
            <div className={classes.adminModalTokenInput}>
              <input
                className={classes.adminModalInput}
                type={showToken ? 'text' : 'password'}
                placeholder="Токен доступа"
                value={modal.user.auth_token}
                onChange={(e) => handleChangeProperty({ auth_token: e.target.value })}
              />
              <button className={classes.showTokenButton} onClick={toggleTokenVisibility}>
                <CustomIcon icon={showToken ? 'hideIcon' : 'showIcon'} />
              </button>
            </div>
          </form>
          <div className={classes.modalUserAccesses}>
            <CustomButton
              onClick={() => handleChangeProperty({ access_asr: !modal.user.access_asr })}
              className={cn(usersListClasses.accessButton, modal.user.access_asr ? usersListClasses.active : usersListClasses.disabled)}
              style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
            >
              <CustomIcon icon="asrLogo" /> ASR
            </CustomButton>
            <CustomButton
              onClick={() => handleChangeProperty({ access_tts: !modal.user.access_tts })}
              className={cn(usersListClasses.accessButton, modal.user.access_tts ? usersListClasses.active : usersListClasses.disabled)}
              style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
            >
              <CustomIcon icon="ttsLogo" /> TTS
            </CustomButton>
            <CustomButton
              onClick={() => handleChangeProperty({ access_console: !modal.user.access_console })}
              className={cn(usersListClasses.accessButton, modal.user.access_console ? usersListClasses.active : usersListClasses.disabled)}
              style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
            >
              <CustomIcon icon="consoleLogo" /> Консоль
            </CustomButton>
            <CustomButton
              onClick={() => handleChangeProperty({ access_admin: !modal.user.access_admin })}
              className={cn(usersListClasses.accessButton, modal.user.access_admin ? usersListClasses.active : usersListClasses.disabled)}
              style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
            >
              <CustomIcon icon="settings" /> Админка
            </CustomButton>
          </div>
          <div className={ttsClasses.ttsModalButtonGroup}>
            <CustomButton onClick={handleCloseModal}>Отмена</CustomButton>
            <CustomButton className={ttsSidebarClasses.sanitizeBtn} onClick={addUser}>
              Добавить
            </CustomButton>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  )
}
